.dashboard-textarea {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 10px;
  border-radius: 12px;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: calc(100% - 20px);
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  border-color: rgba(0, 0, 0, 0.23);
  margin-bottom: 16px;
  resize: none;
  &:disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.36);
    cursor: not-allowed;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdfe2;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4cdd5;
}

.modal-scalex {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100vh;
  overflow-y: scroll;
  transform: translate(-50%, -50%);
  max-width: 100%;
}

.MuiModal-root {
  .containerScoll {
    max-height: 60vh;
    overflow-y: auto;
  }
}

.total-investors,
.lista-usuarios-chat {
  a {
    color: #6a34db;
    font-weight: 700;
  }
}
.inputDemoday{
  input{
    padding-left: 95px;
  }
}
// recaptcha

.g-recaptcha {
  display: inline-block;
}
.showmobile{
  display: none;
}
//extras
@media (max-width: 480px) {
  .showmobile{
    display: block;
  }
  .hiddenmobile{
    display: none;
  }
  .modal-onboarding{
    padding: 20px;
    padding-top: 55px;
  }
  .btn-mobile{
    width: 80vw;
    margin: auto;
  }
  .w100mobile{
    min-width: 80vw;
    width: 100% !important;
    margin-left: 9px;
    margin-bottom: 15px;
  }
  .MuiButton-text{
    min-width: auto;
  }

  //hide label 
  // .MuiPaper-elevation.MuiPaper-rounded{
  //   .MuiListItemButton-root{
  //     .MuiListItemText-root.css-tlelie-MuiListItemText-root{
  //       display: none;
  //     }
  //   }
  // }
  
}



